import mainStyles from "../../css/style.module.css";
import styles from "../../css/device.200240.module.css";
import { useTranslation } from "react-i18next";

function Device200240() {

	const { t } = useTranslation()

	return (
		<div className={styles.container}>
			<section id="KCD200240" className={styles.KCD200240}>
				<h4 className={mainStyles.h4} data-aos="fade-down">
					KCD<br /><span className={styles.small}>200/240</span>
				</h4>
				<div className={styles.content}>
					<div  data-aos="fade-down">
						<div className={styles.mainImages}>
							<img src="/images/device/20240829.313_clip.png" alt="200kW" />
							<img src="/images/device/20240829.316_clip.png" alt="200kW" />
						</div>
						<ul className={styles.titleText}>
							<li>KCD200/240</li>
							<li>{t("급속 충전기")}</li>
						</ul>
					</div>
					<div data-aos="fade-down">
						<div className={styles.description}>
							<span className={styles.name}>KCD200/240</span> 초급속 충전기는 <b>200/240kW</b> 용량으로 사업장, 고속도로 휴게소, 버스 차고지 등에 설치하여 수익 사업이 가능한 모델입니다.
						</div>
						<ul className={styles.features}>
							<li><span className={mainStyles.txt_span}>▪</span> 충전 상태 표시 터치 디스플레이 12.1“  or 27” LCD</li>
							<li><span className={mainStyles.txt_span}>▪</span> 5단계 충전량 표시 LED 및 음성 안내</li>
							<li><span className={mainStyles.txt_span}>▪</span> AI 화재 감지 시 자동 충전 종료(옵션)</li>
							<li><span className={mainStyles.txt_span}>▪</span> 내부 과열 / 과전류 / 누설 전류 차단</li>
						</ul>
					</div>
				</div>
				<div className={styles.spec} data-aos="fade-up">
					<div className={mainStyles.KCA7Series_table}>
						<table>
							<colgroup>
								<col />
								<col />
							</colgroup>
							<tbody>
								<tr>
									<th>모델명</th>
									<td>KCD200</td>
									<td>KCD240</td>
								</tr>
								<tr>
									<th>입력</th>
									<td>3Ø4W, 380VAC, 220kVA</td>
									<td>3Ø4W, 380VAC, 260kVA</td>
								</tr>
								<tr>
									<th>출력</th>
									<td>
										DC 0~1000V, 0~200kW (100kWx2)
										<br />
										전압,전류제어 정밀도 : ±3% (F.S)이내
									</td>
									<td>
										DC 200~1000V, 0~240KW (120kWx2),
										<br />
										전압,전류제어 정밀도 : ±3% (F.S)이내
									</td>
								</tr>
								<tr>
									<th>LCD</th>
									<td colSpan={2}>27” 터치 스크린</td>
								</tr>
								<tr>
									<th>환경조건</th>
									<td colSpan={2}>
										온도 : -25°C ~ 50°C
										<br/>
										습도 : 20% ~ 95% (단, 이슬 맺힘이 없어야 함)
									</td>
								</tr>
								<tr>
									<th>보호등급</th>
									<td colSpan={2}>IP44</td>
								</tr>
								<tr>
									<th>보호기능</th>
									<td colSpan={2}>누설전류, 과전류, 과전압, 융착감지 차단, 지락감지 차단, 과온 대응</td>
								</tr>
								<tr>
									<th>결제방식</th>
									<td colSpan={2}>신용카드, RF카드 (회원카드)</td>
								</tr>
								<tr>
									<th>카메라</th>
									<td colSpan={2}>화재 감지용, FHD, 1 / 2.7-inch HDR CMOS Image Sensor</td>
								</tr>
								<tr>
									<th>충전방식</th>
									<td colSpan={2}>DC COMBO 2 (CCS 2) or DC COMBO 1 (CCS 1)</td>
								</tr>
								<tr>
									<th>통신</th>
									<td colSpan={2}>Ethernet, RS-485</td>
								</tr>
								<tr>
									<th>크기</th>
									<td colSpan={2}>1960(H) x 800(W) x 700(D)mm</td>
								</tr>
								<tr>
									<th>특징</th>
									<td>
										분리충전(Max.100kWx2) 및<br/>
										통합충전(Max.200kW)  기능
									</td>
									<td>
										분리충전(Max.120kWx2) 및<br/>
										통합충전(Max.200kW)  기능
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</section>
			{/*<section id="KCD240DISPENSER" className={styles.KCD240Dispenser}>*/}
			{/*	<div className={styles.content}>*/}
			{/*		<div data-aos="fade-down">*/}
			{/*			<div className={styles.mainImages}>*/}
			{/*				<img src="/images/device/20250107_ALL-2.480_clip.png" alt="240kW" />*/}
			{/*			</div>*/}
			{/*			<ul className={styles.titleText}>*/}
			{/*				<li>KCD240</li>*/}
			{/*				<li>{t("급속 버스 충전기")}</li>*/}
			{/*			</ul>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className={styles.spec}>*/}
			{/*		<div className={mainStyles.KCA7Series_table} data-aos="fade-up">*/}
			{/*			<table>*/}
			{/*				<colgroup>*/}
			{/*					<col />*/}
			{/*					<col />*/}
			{/*				</colgroup>*/}
			{/*				<tbody>*/}
			{/*				<tr>*/}
			{/*					<th></th>*/}
			{/*					<td>PowerBank</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>입력</th>*/}
			{/*					<td>3상 380VAC 265kVA</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>출력</th>*/}
			{/*					<td>DC 200~1000V, 240kW(120kW x 2)</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>통신</th>*/}
			{/*					<td>Ethernet, RS-485</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>보호등급</th>*/}
			{/*					<td>IP55</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>환경조건</th>*/}
			{/*					<td>*/}
			{/*						온도 : -25°C ~ 50°C <br/>*/}
			{/*						습도 : 20% ~ 95% (단, 이슬 맺힘이 없어야 함)*/}
			{/*					</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>보호기능</th>*/}
			{/*					<td>*/}
			{/*						누설전류, 과전류, 과전압, 융착감지 차단,<br/>*/}
			{/*						지락감지 차단, 과온*/}
			{/*					</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>특징</th>*/}
			{/*					<td>분산충전 기능</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>크기</th>*/}
			{/*					<td>?(H) x ?(W) x ?(D)mm </td>*/}
			{/*				</tr>*/}
			{/*				</tbody>*/}
			{/*			</table>*/}
			{/*		</div>*/}
			{/*		<div className={mainStyles.KCA7Series_table} data-aos="fade-up">*/}
			{/*			<table>*/}
			{/*				<colgroup>*/}
			{/*					<col />*/}
			{/*					<col />*/}
			{/*				</colgroup>*/}
			{/*				<tbody>*/}
			{/*				<tr>*/}
			{/*					<th></th>*/}
			{/*					<td>Dispenser</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>입력</th>*/}
			{/*					<td>DC 200~1000V</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>출력</th>*/}
			{/*					<td>DC 200~1000V, 120kW x 2</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>통신</th>*/}
			{/*					<td>Ethernet, RS-485</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>보호등급</th>*/}
			{/*					<td>IP55</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>LCD</th>*/}
			{/*					<td>12.1” 터치 스크린</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>결제 방식</th>*/}
			{/*					<td>신용카드, RF카드 (회원카드)</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>충전 방식</th>*/}
			{/*					<td>DC COMBO 2 (CCS Type2)</td>*/}
			{/*				</tr>*/}
			{/*				<tr>*/}
			{/*					<th>크기</th>*/}
			{/*					<td>1700(H) x 560(W) x 383(D)mm </td>*/}
			{/*				</tr>*/}
			{/*				</tbody>*/}
			{/*			</table>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</section>*/}
		</div>
	);

}

export default Device200240;
