import {useState,useEffect} from 'react';
import styles from '../../css/style.module.css';
import {Link,useLocation} from "react-router-dom"
import { HashLink } from 'react-router-hash-link';
import LanguageButton
    from "./language";
import { useTranslation } from "react-i18next";

const Header = ({onMain, onDevice, onPlatform, onProcedure, onInquiry}) => {
    const [check, setCheck] = useState(false);
    const location = useLocation()
    const { t, i18n } = useTranslation();

    const checkEvent = () => {
        setCheck(!check)
    }

    return (
        <header id="header" className={styles.header}>
            <div className={styles.header_overlay}></div>
            <div className={styles.header_in}>
                <div className={styles.gnb_logo}>
                    <Link to="/">
                        <img alt="logo" src="/images/logo.png" />
                    </Link>
                </div>

                <ul className={styles.gnb_menu}>
                    <li>
                        <Link to="/device">{t("쿨차지충전기")}</Link>
                        <div className={styles.panel}>
                            <HashLink to="/device#KCA7P7PG">KCA7P7PG</HashLink>
                            <HashLink to="/device#KCA7Series">KCA7</HashLink>
                            <HashLink to="/device#KCA11Series">KCA11</HashLink>
                            <HashLink to="/device#KCD35">KCD35</HashLink>
                            <HashLink to="/device#KCD50100">KCD50/100</HashLink>
                            <HashLink to="/device#KCD200240">KCD200/240</HashLink>
                        </div>
                    </li>

                    <li>
                        <HashLink to="/platform#CSMS">{t("쿨차지플랫폼")}</HashLink>
                        <div className={styles.panel}>
                            <HashLink to="/platform#CSMS">{t("충전소 관리")}</HashLink>
                            <HashLink to="/platform#CSFDS">{t("충전소 화재감지")}</HashLink>
                            <HashLink to="/platform#CSIS">{t("충전소 통합정보")}</HashLink>
                            <HashLink to="/platform#CSSS">{t("충전소 보안")}</HashLink>
                        </div>
                    </li>

                    {/*<li>*/}
                    {/*    <Link to="/installation/inquiry">설치신청</Link>*/}
                    {/*    <div className={styles.panel}>*/}
                    {/*        <Link to="/installation/inquiry" >설치신청</Link>*/}
                    {/*        <Link to="/installation/procedure" >설치절차</Link>*/}
                    {/*    </div>*/}
                    {/*</li>*/}

                    <li>
                        <Link to="/support/inquiries">{t("기술지원")}</Link>
                        <div className={styles.panel}>
                            {/* <Link to="/support/inquiries" >1:1 문의</Link> */}
                            <Link to="/support/menuals">{t("자료실")}</Link>
                        </div>
                    </li>
                    {i18n.language === "ko" && (
                        <li>
                            <Link to="/dealership">{t("대리점모집")}</Link>
                        </li>
                    )}
                </ul>

                {/* 햄버거 메뉴 */}
                <div className={styles.gnb_btn}>
                    <div className={styles.hamburger}>
                        <input checked={check} type='checkbox' id='menuToggle' className={styles.menuToggle} style={{display: 'none'}} readOnly />
                        <label className={`${styles.gnb_toggleBtn} ${styles.gnb_toggleBtn_x}`} onClick={checkEvent}>
                            <img alt="toggle" src="/images/toggle.png" />
                            <img alt="toggle-x" src="/images/toggle-x.png" />
                        </label>

                        <nav>
                            <ul>
                                <li>
                                    <div className={styles.accordion}>
                                        <input type="radio" name="acc" id="acc-1"/>
                                        <label htmlFor="acc-1" className={styles.acc_label}>{t("쿨차지충전기")}</label>
                                        <div className={`${styles.acc_content} ${styles.menu02}`}>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/device#KCA7P7PG">KCA7P7PG</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/device#KCA7Series">KC7</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/device#KCA11Series">KC11 </HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/device#KCD35">KCD 30/40</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/device#KCD50100">KCD 100/150</HashLink>
                                            </div>
                                        </div>
                                    </div>
                                    <span className={styles.acc_arrow}><img alt="arrow-d"
                                                                            src="/images/arrow-d.png"/></span>
                                </li>

                                <li>
                                    <div className={styles.accordion}>
                                        <input type="radio" name="acc" id="acc-2"/>
                                        <label htmlFor="acc-2" className={styles.acc_label}>
                                            {t("쿨차지플랫폼")}
                                        </label>
                                        <div className={styles.acc_content}>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/platform#CSMS">{t("충전소 관리")}</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/platform#CSFDS">{t("충전소 화재감지")}</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/platform#CSIS">{t("충전소 통합정보")}</HashLink>
                                            </div>
                                            <div>
                                                <HashLink onClick={checkEvent} to="/platform#CSSS">{t("충전소 보안")}</HashLink>
                                            </div>
                                        </div>
                                    </div>
                                    <span className={styles.acc_arrow}><img alt="arrow-d"
                                                                            src="/images/arrow-d.png"/></span>
                                </li>

                                {/*<li>*/}
                                {/*    <div className={styles.accordion}>*/}
                                {/*        <input type="radio" name="acc" id="acc-3" />*/}
                                {/*        <label htmlFor="acc-3" className={styles.acc_label}>*/}
                                {/*            설치신청*/}
                                {/*        </label>*/}
                                {/*        <div className={styles.acc_content}>*/}
                                {/*            <div>*/}
                                {/*                <Link to="/installation/inquiry">설치신청</Link>*/}
                                {/*            </div>*/}
                                {/*            <div>*/}
                                {/*                <Link to="/installation/procedure">설치절차</Link>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <span className={styles.acc_arrow}><img alt="arrow-d" src="/images/arrow-d.png" /></span>*/}
                                {/*</li>*/}

                                <li>
                                    <div className={styles.accordion}>
                                        <input type="radio" name="acc" id="acc-4"/>
                                        <label htmlFor="acc-4" className={styles.acc_label}>
                                            {t("기술지원")}
                                        </label>
                                        <div className={styles.acc_content}>
                                            {/* <div>
                                                <Link to="/support/inquiries">1:1 문의</Link>
                                            </div> */}
                                            <div>
                                                <Link to="/support/menuals">{t("자료실")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <span className={styles.acc_arrow}><img alt="arrow-d"
                                                                            src="/images/arrow-d.png"/></span>
                                </li>
                                {i18n.language === "ko" && (
                                    <li>
                                        <div className={styles.accordion}>
                                            <label htmlFor="acc-5" className={styles.acc_label}>
                                                <Link to="/dealership">{t("대리점모집")}</Link>
                                            </label>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>


                <div className={styles.iconFlexBox}>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=net.koolsign.charge_app"
                       className={styles.header_app_download_icon_google}>
                        <img src="/images/google_play_logo.png"/>
                    </a>
                    <a target="_blank"
                       href="https://apps.apple.com/us/app/koolcharge-%EC%BF%A8%EC%B0%A8%EC%A7%80-%EB%82%98%EB%A5%BC-%EC%95%8C%EC%95%84%EB%B3%B4%EB%8A%94-ai%EC%B6%A9%EC%A0%84/id1663150470"
                       className={styles.header_app_download_icon_apple}>
                        <img src="/images/apple_store_logo.png"/>
                    </a>
                    <a target="_blank" href="https://pf.kakao.com/_ymxcUxj" className={styles.kakaoBtn}>
                        <img src="/images/kakao_cropped.png"/>
                    </a>
                    <a target="_blank" href="https://m.youtube.com/@koolcharge" className={styles.youtubeBtn}>
                        <img src="/images/youtube.png"/>
                    </a>
                    <a target="_blank" aria-hidden="false" aria-label="Facebook" href="https://facebook.com/koolcharge" role="link" tabIndex="0">
                        <svg viewBox="0 0 36 36"  fill="url(#:0R1kjakqbkq75b5klba:)">
                            <defs>
                                <linearGradient x1="50%" x2="50%" y1="97.0782153%" y2="0%" id=":0R1kjakqbkq75b5klba:">
                                    <stop offset="0%" stopColor="#0062E0"></stop>
                                    <stop offset="100%" stopColor="#19AFFF"></stop>
                                </linearGradient>
                            </defs>
                            <path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
                            <path style={{fill:"white"}} d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"></path>
                        </svg>
                    </a>
                    <LanguageButton />
                </div>

            </div>
        </header>
    )
};

export default Header;
